import { SET_SELECTED_CAMERA } from "@/store/mutations.js";
export const cameras = {
  getFormData: (state) => {
    let formData = new FormData();
    formData.append("id", state.selectedCamera.id);
  
    formData.append("aws_folder", state.selectedCamera.aws_folder);
    formData.append("aws_bucket", state.selectedCamera.aws_bucket);

    return formData;
  },
  saveCallBack: (response, state, dispatch) => {
    dispatch("fetchList");
  },
  validate: (state) => {
    return state.selectedCamera.aws_folder != "" && state.selectedCamera.aws_bucket != ""
    
  },
  reset: (commit) => {
    commit(SET_SELECTED_CAMERA, {
      id: null,
      aws_bucket: "",
      aws_folder: "",
    });
  },
  getFetchParams(state) {
    let params = {};

    if (state.filters.company) {
      params.company = state.filters.company;
    }
    if (state.filters.project) {
      params.project = state.filters.project;
    }

    return params;
  },
};

<template>
    <div class="companies-table">
      <button-group />
      <table class="table is-fullwidth is-striped is-hoverable">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>User</th>
            <th>Is Canceled</th>
            <th>Status</th>

          </tr>
        </thead>
        <tbody v-if="contracts.length > 0">
          <tr v-for="(contract, i) in contracts" :key="i" @click="edit(contract)">
            <td>{{ contract.id }}</td>
            <td>{{ contract.company.name }}</td>
            <td>{{ contract.user.email }}</td>
            <td>{{ contract.is_canceled }}</td>
            <td>{{ contract.status }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions, mapMutations } from "vuex";
  import { convertISO8601ToTurkeyTime } from "../utils/date_converter";
  import ButtonGroup from "@/components/ButtonGroup.vue";
  export default {
    components: {
      "button-group": ButtonGroup,
    },
    computed: {
      ...mapState(["contracts", "isLoadingSync"]),
    },
    methods: {
      ...mapActions(["fetchList"]),
      ...mapMutations(["SET_SELECTED_CONTRACT", "SET_MODAL_STATE"]),
      convertISO8601ToTurkeyTime,
      edit(contract) {
        console.log(contract);
        this.SET_SELECTED_CONTRACT(contract);
        this.SET_MODAL_STATE(true);
      },
    },
    mounted() {
      this.fetchList();
    },
  };
  </script>
  
  <style></style>
  
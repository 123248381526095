import { SET_SELECTED_CONTRACT } from "@/store/mutations.js";
export const contracts = {
  fields:  {
    id: 0,
    created_at: new Date(), 
    updated_at: new Date(), 
    company_id: 0, 
    user_id: 0, 
    package_id: 0, 
    start_date: new Date(),
    end_date:  new Date(), 
    amount: 0.0,
    currency: "USD",
    package_name: "",
    features: {},
    status: "",
    is_trial: false,
    next_invoice_date: null,
    is_canceled: false,
  },
  getFormData: (state) => {
    let formData = new FormData();
    formData.append("id",state.selectedContract.id);
    formData.append("start_date",state.selectedContract.start_date);

    if (state.selectedContract.end_date.Valid) {
        formData.append("end_date",state.selectedContract.end_date.Time);
    }
    
    if (state.selectedContract.next_invoice_date.Valid) {
        formData.append("next_invoice_date",state.selectedContract.next_invoice_date.Time);
    }
    
    formData.append("is_canceled",state.selectedContract.is_canceled);
    formData.append("user_count",state.selectedContract.features.user_count);
    formData.append("project_count",state.selectedContract.features.project_count);
    formData.append("camera_count",state.selectedContract.features.camera_count);
    return formData;
  },
  saveCallBack: (response, state, dispatch) => {
    state.selectecContract.id = response.data.id;
    dispatch("fetchList");
  },
  validate: (state) => {
    return state.selectedContract.id > 1
  },
  reset: (commit) => {
    commit(SET_SELECTED_CONTRACT, {
        id: 0,
        created_at: new Date(), 
        updated_at: new Date(), 
        company_id: 0, 
        user_id: 0, 
        package_id: 0, 
        start_date: new Date(),
        end_date:  new Date(), 
        amount: 0.0,
        currency: "USD",
        package_name: "",
        features: {},
        status: "",
        is_trial: false,
        next_invoice_date: null,
        is_canceled: false,
      });
  },
};

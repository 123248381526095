<template>
  <div :class="['modal', isModalOpen ? 'is-active' : '']">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit {{ activeTab.name }}</p>
        <button class="delete" aria-label="close" @click="cancel"></button>
      </header>
      <section class="modal-card-body">
        <slot></slot>
      </section>
      <footer v-if="modalActiveTabIndex===0" class="modal-card-foot">
        <button
          :class="['button', 'is-success', isSaving ? 'is-loading' : '']"
          @click="save"
        >
          Save
        </button>
        <button class="button" @click="cancel">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState, mapGetters } from "vuex";
export default {
  methods: {
    ...mapMutations(["SET_MODAL_STATE", "SET_MODAL_ACTIVE_TAB_INDEX"]),
    ...mapActions(["save", "reset"]),
    cancel() {
      this.SET_MODAL_STATE(false);
      this.SET_MODAL_ACTIVE_TAB_INDEX(0);
      this.reset();
    },
  },
  computed: {
    ...mapState(["isModalOpen", "isSaving","modalActiveTabIndex"]),
    ...mapGetters(["activeTab"]),
  },
};
</script>

<style></style>

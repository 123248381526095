<template>
    <table class="table is-fullwidth is-striped is-hoverable">
         <thead>
           <tr>
             <th>Amount</th>
             <th>Currency</th>
             <th>Due Date</th>
             <th>Is Paid</th>
             <th>Next Payment Try</th>
             <th></th>
           
           </tr>
         </thead>
         <tbody v-if="invoices.length > 0">
           <tr v-for="(invoice, i) in invoices" :key="i" @click="edit(invoice)">
             <td><div class="control"><input style="width: unset;" class="input" type="text" v-model="invoices[i].amount" /></div></td>
             <td><div class="control"><input style="width: unset;" class="input" type="text" v-model="invoices[i].currency" /></div></td>
             <td><div class="control"><input style="width: unset;" class="input" type="text" v-model="invoices[i].due_date" /></div></td>
             <td><div class="control"><input style="width: unset;" class="input" type="text" v-model="invoices[i].is_paid" /></div></td>
             <td><div class="control"><input style="width: unset;" class="input" type="text" v-model="invoices[i].next_payment_try_date" /></div></td>
             <td><div class="control"><button class="button" @click="save(invoices[i])">Save</button></div></td>
           </tr>
         </tbody>
       </table>
   </template>
   
   <script>
   import { mapState } from "vuex";
   
   export default {
     data() {
       return {
         invoices: [],
       };
     },
     computed: {
       ...mapState(["selectedCompany"]),
     },
     methods:{
       getInvoices(){
         this.$http.get(`invoices?page=1&size=20&company_id=${this.selectedCompany.id}`).then((response) => {
           this.invoices = response.data.data
         });
       },
       save(invoice) {
         var formData = new FormData();
         formData.append("id", invoice.id);
         formData.append("due_date", invoice.due_date);
         formData.append("amount", invoice.amount);
         formData.append("currency", invoice.currency);
         formData.append("is_paid", invoice.is_paid);
         formData.append("is_retried_before", invoice.is_retried_before);
         formData.append("payment_try_count", invoice.payment_try_count);
   
         this.$http.post("invoices/", formData).then((response) => {
           if (response.status !== 200) {
             this.$toast.open({
               position: "top",
               type: "error",
               message:
                 "Error occurred",
             });
             return;
           }
           this.$toast.open({
             position: "top",
             type: "success",
             message: "Updated",
           });
           
         });
   
         setTimeout(() => {
           this.getInvoices();    
         }, 1000);
       
   
       }
     },
     mounted() {
       this.getInvoices();
     },
   };
   </script>
   
   <style>
   
   </style>
   
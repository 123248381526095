<template>
  <modal>
    <div class="tabs is-boxed">
      <ul>
        <li
          v-for="(tab, i) in tabs"
          :class="[modalActiveTabIndex === i ? 'is-active' : '']"
          :key="i"
        >
          <a @click="SET_MODAL_ACTIVE_TAB_INDEX(i)">{{ tab.name }}</a>
        </li>
      </ul>
    </div>
    <component :is="tabs[modalActiveTabIndex].component" />
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import General from "./tabs/General.vue";
import Invoices from "./tabs/Invoices.vue";
import Payments from "./tabs/Payments.vue";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    Modal,
    General,
    Invoices,
    Payments
  },
  data() {
    return {
      tabs: [
        {
          name: "General",
          component: General,
          isActive: true,
        },
        {
          name: "Invoices",
          component: Invoices,
          isActive: false,
        },
        {
          name: "Payments",
          component: Payments,
          isActive: false,
        },
      ],
      deploymentType: "automatic",
    };
  },
  computed: {
    ...mapState(["modalActiveTabIndex"]),
  },
  methods: {
    ...mapMutations(["SET_MODAL_ACTIVE_TAB_INDEX"]),
  },
};
</script>

<style scoped>
.level-item {
  flex-shrink: 1;
  padding-right: 1em;
}
</style>

<template>
  <div>
    <div class="field">
      <label class="label">ID</label>
      <div class="control">
        <input
          class="input"
          type="text"
          v-model="selectedProject.id"
          disabled
        />
      </div>
    </div>
    <div class="field">
      <label class="label">Name</label>
      <div class="control">
        <input class="input" type="text" v-model="selectedProject.name" />
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      companies: [],
    };
  },
  computed: {
    ...mapState(["selectedProject"]),
  },
  mounted() {
  },
};
</script>

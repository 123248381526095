<template>
  <div class="projects-table">
    <button-group />
    <table class="table is-fullwidth is-striped is-hoverable">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Company</th>
        
        </tr>
      </thead>
      <tbody v-if="projects.length > 0">
        <tr v-for="(project, i) in projects" :key="i" @click="edit(project)">
          <td>{{ project.id }}</td>
          <td>{{ project.name }}</td>
          <td>{{ project.company.name }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import ButtonGroup from "@/components/ButtonGroup.vue";
export default {
  components: {
    "button-group": ButtonGroup,
  },
  computed: {
    ...mapState(["projects", "isLoadingSync"]),
  },

  methods: {
    ...mapActions(["fetchList"]),
    ...mapMutations(["SET_SELECTED_PROJECT", "SET_MODAL_STATE"]),
    edit(project) {
      this.SET_SELECTED_PROJECT(project);
      this.SET_MODAL_STATE(true);
    },
  },
  mounted() {
    this.fetchList();
  },
};
</script>

<style></style>

<template>
  <div>
    <div class="field">
      <label class="label">ID</label>
      <div class="control">
        <input
          class="input"
          type="text"
          v-model="selectedContract.id"
          disabled
        />
      </div>
    </div>
    <div class="field">
      <label class="label">Start</label>
      <div class="control">
        <input class="input" type="text" v-model="selectedContract.start_date" />
      </div>
    </div>
    <div class="field">
      <label class="label">End</label>
      <div class="control">
        <input class="input" type="text" v-model="selectedContract.end_date.Time" />
      </div>
    </div>
    <div class="field">
      <label class="label">Next Invoice</label>
      <div class="control">
        <input class="input" type="text" v-model="selectedContract.next_invoice_date.Time" />
      </div>
    </div>
    <div class="field">
      <label class="label">User Count</label>
      <div class="control">
        <input class="input" type="text" v-model="selectedContract.features.user_count" />
      </div>
    </div>
    <div class="field">
      <label class="label">Camera Count</label>
      <div class="control">
        <input class="input" type="text" v-model="selectedContract.features.camera_count" />
      </div>
    </div>
    <div class="field">
      <label class="label">Project Count</label>
      <div class="control">
        <input class="input" type="text" v-model="selectedContract.features.project_count" />
      </div>
    </div>
    <div class="control">
        <span
          :class="[
            'select',
            selectedContract.is_canceled === false ? 'is-success' : 'is-danger',
          ]"
        >
          <select v-model="selectedContract.is_canceled">
            <option value=true>Canceled</option>
            <option value=false>Active</option>
          </select>
        </span>
      </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["selectedContract"]),
  },
};
</script>

<style></style>

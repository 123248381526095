<template>
  <div class="companies-table">
    <button-group />
    <table class="table is-fullwidth is-striped is-hoverable">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Oluşturma</th>
          <th>Güncelleme</th>
        </tr>
      </thead>
      <tbody v-if="companies.length > 0">
        <tr v-for="(company, i) in companies" :key="i" @click="edit(company)">
          <td>{{ company.id }}</td>
          <td>{{ company.name }}</td>
          <td>{{ convertUnixToTurkeyTime(company.created_at) }}</td>
          <td>{{ convertUnixToTurkeyTime(company.updated_at) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { convertUnixToTurkeyTime } from "../utils/date_converter";
import ButtonGroup from "@/components/ButtonGroup.vue";
export default {
  components: {
    "button-group": ButtonGroup,
  },
  computed: {
    ...mapState(["companies", "isLoadingSync"]),
  },
  methods: {
    ...mapActions(["fetchList"]),
    ...mapMutations(["SET_SELECTED_COMPANY", "SET_MODAL_STATE"]),
    convertUnixToTurkeyTime,
    edit(company) {
      console.log(company);
      this.SET_SELECTED_COMPANY(company);
      this.SET_MODAL_STATE(true);
    },
  },
  mounted() {
    this.fetchList();
  },
};
</script>

<style></style>

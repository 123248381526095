<template>
  <div>
    <div class="field">
      <label class="label">ID</label>
      <div class="control">
        <input class="input" type="text" v-model="selectedCamera.id" disabled />
      </div>
    </div>
    <div class="field">
      <label class="label">Name</label>
      <div class="control">
        <input class="input" type="text" v-model="selectedCamera.name" disabled />
      </div>
    </div>
    <div class="field">
      <label class="label">Project</label>
      <div class="control">
        <input class="input" type="text" v-model="selectedCamera.project.name" disabled />
      </div>
    </div>
    <div class="field">
      <label class="label">Project</label>
      <div class="control">
        <input class="input" type="text" v-model="selectedCamera.project.company.name" disabled />
      </div>
    </div>
    <div class="field">
      <label class="label">AWS Bucket</label>
      <div class="control">
        <input class="input" type="text" v-model="selectedCamera.aws_bucket" />
      </div>
    </div>
    <div class="field">
      <label class="label">AWS Folder</label>
      <div class="control">
        <input class="input" type="text" v-model="selectedCamera.aws_folder" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["selectedCamera"]),
  },
  mounted() {

  },
};
</script>

<style></style>

<template>
  <div>
    <div class="field">
      <label class="label">ID</label>
      <div class="control">
        <input class="input" type="text" v-model="selectedUser.id" disabled />
      </div>
    </div>
    <div class="field">
      <label class="label">Full Name</label>
      <div class="control">
        <input class="input" type="text" v-model="selectedUser.full_name" />
      </div>
    </div>
    <div class="field">
      <div class="control">
        <label class="label">E-Mail</label>
      </div>
    </div>
    <div class="field has-addons">
      <div class="control">
        <input class="input" type="text" v-model="selectedUser.email" />
      </div>
      <div class="control">
        <span class="select">
          <select v-model="selectedUser.email_validation">
            <option value=1>Verified</option>
            <option value=0>Not Verified</option>
          </select>
        </span>
      </div>
    </div>

    <div class="field">
      <label class="label">Is Active</label>
      <div class="control">
        <span class="select">
          <select v-model="selectedUser.is_active">
            <option value=1>Active</option>
            <option value=0>Passive</option>
          </select>
        </span>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["selectedUser"]),
  },
};
</script>

<style></style>

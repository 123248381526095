<template>
    <table class="table is-fullwidth is-striped is-hoverable">
         <thead>
           <tr>
             <th>Full Name</th>
             <th>Email</th>
             <th>Email Validation</th>
             <th>Is Active</th>
             <th></th>
             <th></th>
           
           </tr>
         </thead>
         <tbody v-if="users.length > 0">
           <tr v-for="(user, i) in users" :key="i" @click="edit(user)">
             <td><div class="control"><input style="width: unset;" class="input" type="text" v-model="users[i].full_name" /></div></td>
             <td><div class="control"><input style="width: unset;" class="input" type="text" v-model="users[i].email" /></div></td>
             <td><span class="select"><select v-model="users[i].email_validation"><option value=1>Verified</option><option value=0>Not Verified</option></select></span></td>
             <td><span class="select"><select v-model="users[i].is_active"><option value=1>Active</option><option value=0>Passive</option></select></span></td>
             <td><div class="control"><button class="button" @click="save(users[i])">Save</button></div></td>
             <td><div class="control"><button class="button" @click="login(user)">Login</button></div></td>
           </tr>
         </tbody>
       </table>
   </template>
   
   <script>
   import { mapState } from "vuex";
   
   export default {
     data() {
       return {
         users: [],
       };
     },
     computed: {
       ...mapState(["selectedCompany"]),
     },
     methods:{
       getUsers(){
         this.$http.get(`users?page=1&size=20&company_id=${this.selectedCompany.id}`).then((response) => {
           this.users = response.data.data
         });
       },
       login(user) {
          var formData = new FormData();
            formData.append("user_id", user.id);

            this.$http.post("admin-login-token", formData).then((response) => {
              if (response.status !== 201) {
                this.$toast.open({
                  position: "top",
                  type: "error",
                  message:
                    "Error occurred",
                });
                return;
              }

              window.open(`${process.env.VUE_APP_FE_HOST}?admin_login_token=${response.data.token}`);

            
            });
      },
       save(user) {
         var formData = new FormData();
         formData.append("id", user.id);
         formData.append("full_name", user.full_name);
         formData.append("email", user.email);
         formData.append("is_active", user.is_active);

         this.$http.post("users/", formData).then((response) => {
           if (response.status !== 200) {
             this.$toast.open({
               position: "top",
               type: "error",
               message:
                 "Error occurred",
             });
             return;
           }
           this.$toast.open({
             position: "top",
             type: "success",
             message: "Updated",
           });
           
         });
   
         setTimeout(() => {
           this.getUsers();    
         }, 1000);
       
   
       }
     },
     mounted() {
       this.getUsers();
     },
   };
   </script>
   
   <style>
   
   </style>
   
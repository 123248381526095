

export function convertUnixToTurkeyTime(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const options = {
      timeZone: 'Europe/Istanbul',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    return date.toLocaleString('tr-TR', options);
  }

 export function convertISO8601ToTurkeyTime(utcDate) {
    const date = new Date(utcDate);
    const options = {
        timeZone: 'Europe/Istanbul',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };
    const turkeyTime = new Intl.DateTimeFormat('tr-TR', options).format(date);
    return turkeyTime;
}

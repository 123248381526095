<template>
  <table class="table is-fullwidth is-striped is-hoverable">
       <thead>
         <tr>
           <th>Created At</th>
           <th>Amount</th>
           <th>Currency</th>
         </tr>
       </thead>
       <tbody v-if="payments.length > 0">
         <tr v-for="(payment, i) in payments" :key="i">
           <td>{{convertISO8601ToTurkeyTime(payment.created_at)}}</td>
           <td>{{payment.amount}}</td>
           <td>{{payment.currency}}</td>
          </tr>
       </tbody>
     </table>
 </template>
 
 <script>
 import { mapState } from "vuex";
 import { convertISO8601ToTurkeyTime } from "../../../../utils/date_converter";
 
 export default {
   data() {
     return {
       payments: [],
     };
   },
   computed: {
     ...mapState(["selectedContract"]),
   },
   methods:{
    convertISO8601ToTurkeyTime,
     getPayments(){
       this.$http.get(`payments?page=1&size=20&contract_id=${this.selectedContract.id}`).then((response) => {
         this.payments = response.data.data
       });
     },
     
   },
   mounted() {
     this.getPayments();
   },
 };
 </script>
 
 <style>
 
 </style>
 
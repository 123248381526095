import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";

import http from "@/plugins/http";

import LogRocket from "logrocket";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
if (process.env.NODE_ENV === "production") {
  LogRocket.init("2fgkyb/natilon-web-app");
}


Vue.use(http);
Vue.use(VueToast);

import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
import "./assets/main.scss";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

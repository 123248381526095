import { SET_SELECTED_USER } from "@/store/mutations.js";
export const users = {
  getFormData: (state) => {
    let formData = new FormData();
    formData.append("full_name", state.selectedUser.full_name);
    formData.append("email", state.selectedUser.email);
    formData.append("id", state.selectedUser.id);
    formData.append("email_validation", state.selectedUser.email_validation);
    formData.append("is_active", state.selectedUser.is_active);
    return formData;
  },
  reset: (commit) => {
    commit(SET_SELECTED_USER, {
      id: null,
      full_name: "",
      email: "",
      emailValidation: 0
    });
  },
  saveCallBack: (response, state, dispatch) => {
    dispatch("fetchList");
  },
  validate: (state) => {
    return (
      state.selectedUser.full_name?.length > 2 &&
      state.selectedUser.email
    );
  },
  getFetchParams: () => {
    let params = {};
    return params;
  },
};

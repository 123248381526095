export const SET_IS_AUTHENTICATED = "SET_IS_AUTHENTICATED";
export const SET_USER_ID = "SET_USER_ID";
export const SET_USER_FULLNAME = "SET_USER_FULLNAME";
export const SET_USER_PERMISSONS = "SET_USER_PERMISSONS";

export const SET_COMPANIES = "SET_COMPANIES";
export const SET_SELECTED_COMPANY = "SET_SELECTED_COMPANY";

export const SET_PROJECTS = "SET_PROJECTS";
export const SET_SELECTED_PROJECT = "SET_SELECTED_PROJECT";

export const SET_CAMERAS = "SET_CAMERAS";
export const SET_SELECTED_CAMERA = "SET_SELECTED_CAMERA";

export const SET_USERS = "SET_USERS";
export const SET_SELECTED_USER = "SET_SELECTED_USER";

export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const SET_MODAL_STATE = "SET_MODAL_STATE";
export const SET_MODAL_ACTIVE_TAB_INDEX = "SET_MODAL_ACTIVE_TAB_INDEX";

export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_PAGE_SIZE = "SET_PAGE_SIZE";
export const SET_TOTAL_RECORDS = "SET_TOTAL_RECORDS";

export const SET_SEARCH = "SET_SEARCH";
export const SET_FILTERS_ROLE = "SET_FILTERS_ROLE";
export const SET_FILTERS_STATUS = "SET_FILTERS_STATUS";
export const SET_FILTERS_COMPANY = "SET_FILTERS_COMPANY";
export const SET_FILTERS_PROJECT = "SET_FILTERS_PROJECT";
export const SET_FILTERS_SHOW_DELETED = "SET_FILTERS_SHOW_DELETED";
export const SET_IS_LOADING_SYNC = "SET_IS_LOADING_SYNC";
export const SET_IS_SAVING = "SET_IS_SAVING";

export const SET_CONTRACTS = "SET_CONTRACTS";
export const SET_SELECTED_CONTRACT = "SET_SELECTED_CONTRACT";


export const mutations = {
  [SET_IS_LOADING_SYNC](state, payload) {
    state.isLoadingSync = payload;
  },
  [SET_IS_AUTHENTICATED](state, status) {
    state.user.isAuthenticated = status;
  },
  [SET_USER_FULLNAME](state, name) {
    state.user.fullName = name;
  },
  [SET_USER_ID](state, id) {
    state.user.id = id;
  },
  [SET_USER_PERMISSONS](state, permissions) {
    state.user.permissions = permissions;
  },
  [SET_COMPANIES](state, payload) {
    state.companies = payload;
  },
  [SET_SELECTED_COMPANY](state, payload) {
    state.selectedCompany = payload;
  },
  [SET_PROJECTS](state, payload) {
    state.projects = payload;
  },
  [SET_SELECTED_PROJECT](state, payload) {
    state.selectedProject = payload;
  },
  [SET_CAMERAS](state, payload) {
    state.cameras = payload;
  },
  [SET_SELECTED_CAMERA](state, payload) {
    state.selectedCamera = payload;
  },
  [SET_USERS](state, payload) {
    state.users = payload;
  },
  [SET_MODAL_STATE](state, payload) {
    state.isModalOpen = payload;
  },

  [SET_MODAL_ACTIVE_TAB_INDEX](state, payload) {
    state.modalActiveTabIndex = payload;
  },

  [SET_SELECTED_USER](state, payload) {
    state.selectedUser = payload;
  },

  [SET_CURRENT_PAGE](state, payload) {
    let tabIndex = payload.tabIndex;
    let page = payload.page;
    state.tabs[tabIndex].currentPage = page;
  },
  [SET_PAGE_SIZE](state, payload) {
    let tabIndex = payload.tabIndex;
    let pageSize = payload.pageSize;
    state.tabs[tabIndex].pageSize = pageSize;
  },
  [SET_TOTAL_RECORDS](state, payload) {
    let tabIndex = payload.tabIndex;
    let totalRecords = payload.totalRecords;
    let totalPages = payload.totalPages;
    state.tabs[tabIndex].totalRecords = totalRecords;
    state.tabs[tabIndex].totalPages = totalPages;
  },
  [SET_ACTIVE_TAB](state, payload) {
    for (let key in state.tabs) {
      if (state.tabs[key].table === payload) {
        state.tabs[key].isActive = true;
      } else {
        state.tabs[key].isActive = false;
      }
    }
  },

  [SET_SEARCH](state, payload) {
    state.search = payload;
  },

  [SET_FILTERS_ROLE](state, payload) {
    state.filters.role = payload;
  },
  [SET_FILTERS_STATUS](state, payload) {
    state.filters.status = payload;
  },
  [SET_FILTERS_COMPANY](state, payload) {
    state.filters.company = payload;
  },
  [SET_FILTERS_PROJECT](state, payload) {
    state.filters.project = payload;
  },
  [SET_FILTERS_SHOW_DELETED](state, payload) {
    state.filters.showDeleted = payload;
  },
  [SET_IS_SAVING](state, payload) {
    state.isSaving = payload;
  },
  [SET_CONTRACTS](state, payload) {
    state.contracts = payload;
  },
  [SET_SELECTED_CONTRACT](state, payload) {
    state.selectedContract = payload;
  }
};

<template>
  <div class="columns">
    <div class="column">
      <input
        type="text"
        class="input"
        v-model="search"
        placeholder="Type keyword and hit enter"
        @keyup="searchFilter"
        @keyup.esc="searchClear"
      />
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      searchTimeout: null,
    };
  },
  methods: {
    ...mapActions(["reset", "fetchList"]),
    ...mapMutations([
      "SET_MODAL_STATE",
      "SET_SEARCH",
      "SET_FILTERS_ROLE",
      "SET_FILTERS_STATUS",
      "SET_FILTERS_COMPANY",
      "SET_FILTERS_PROJECT",
      "SET_FILTERS_SHOW_DELETED",
      "SET_CURRENT_PAGE",
    ]),
    openModal() {
      this.reset();
      this.SET_MODAL_STATE(true);
    },
    reload() {
      console.log(this.activeTab.table);
      this.fetchList();
    },
    resetPageNumber() {
      this.SET_CURRENT_PAGE({
        tabIndex: this.activeTabIndex,
        page: 1,
      });
    },
    resetFilters() {
      this.SET_CURRENT_PAGE({
        tabIndex: this.activeTabIndex,
        page: 1,
      });
      this.SET_SEARCH("");
      this.SET_FILTERS_ROLE("");
      this.SET_FILTERS_STATUS("");
      this.SET_FILTERS_COMPANY("");
      this.SET_FILTERS_PROJECT("");
      this.SET_FILTERS_SHOW_DELETED(false);
      this.reload();
    },
    searchClear() {
      this.resetPageNumber();
      this.SET_SEARCH("");
      this.reload();
    },
    searchFilter() {
      clearTimeout(this.searchTimeout);
      if (this.search.length < 3) return;

      this.searchTimeout = setTimeout(() => {
        this.resetPageNumber();
        this.SET_SEARCH(this.search);
        this.reload();
      }, 500);
    },
  },
  computed: {
    ...mapGetters(["activeTab", "activeTabIndex"]),
    ...mapState({
      companies: "companies",
      projects: "projects",
      isLoadingSync: "isLoadingSync",
    }),
    filteredProjects() {
      return this.projects.filter(
        (project) => project.company_id === this.filterCompany
      );
    },
    filterRole: {
      get() {
        return this.$store.state.filters.role;
      },
      set(value) {
        this.SET_FILTERS_ROLE(value);
      },
    },
    filterStatus: {
      get() {
        return this.$store.state.filters.status;
      },
      set(value) {
        this.SET_FILTERS_STATUS(value);
      },
    },
    filterCompany: {
      get() {
        return this.$store.state.filters.company;
      },
      set(value) {
        this.SET_FILTERS_COMPANY(value);
      },
    },
    filterProject: {
      get() {
        return this.$store.state.filters.project;
      },
      set(value) {
        this.SET_FILTERS_PROJECT(value);
      },
    },
    filterShowDeleted: {
      get() {
        return this.$store.state.filters.showDeleted;
      },
      set(value) {
        this.SET_FILTERS_SHOW_DELETED(value);
      },
    },
    search: {
      get() {
        return this.$store.state.search;
      },
      set(value) {
        this.SET_SEARCH(value);
      },
    },
  },
};
</script>

<style></style>